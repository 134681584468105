/*
 * @Author: diaowangtao
 * @Date: 2020-08-18 17:19:10
 * @Description: 添加获取轮播图接口
 */

import request from '@/utils/request'

export function contactUsDetail(data = {}) {
  return request({
    url: '/contactUs/',
    method: 'post',
    data
  })
}

export function contactUs(data = {}) {
  return request({
    url: '/contactUs/post',
    method: 'post',
    data
  })
}

export function contactBanner(data = {}) {
  return request({
    url: '/contactUs/banner',
    method: 'post',
    data
  })
}
