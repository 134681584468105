<!--
 * @Author: diaowangtao
 * @Date: 2020-08-21 09:00:10
 * @Description: 
-->
<template>
  <div class="banner" :bg_banner="bg_banner" :link="link" :style="{backgroundImage:'url('+bg_banner+')'}" @click="goToLink()"></div>
</template>

<script>
export default {
    name:'myBanner',
    props:{
        bg_banner:'',
        link:''
    },
    methods: {
    goToLink(link){
      this.$emit("goToLink",this.link)
    }}
}
</script>

<style lang='scss'>
.banner {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

}
</style>