<!--
 * @Author: diaowangtao
 * @Date: 2020-08-19 14:40:16
 * @Description: 按钮组件
-->
<template>
  <div class="button hand" :text="text" @click="goToPage(1)">{{text|sLang}}</div>
</template>

<script>
export default {
  name: 'myButton',
  props: {
    text: '',
  },

  data() {
    return {}
  },
  methods: {
    goToPage(i) {
      this.$emit('goToPage', i)
    },
  },
}
</script>

<style lang="scss">
.button {
  font-size: 14px;
  color: #303a51;
  width: 160px;
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  text-align: center;
  background: url('../../assets/img/arrows.png') no-repeat right 40px center
    #dab678;
  background-size: 7px;
  padding-right: 15px;
}
.button:hover{
    background-color:#DAAA57;
}
</style>