<!--
 * @Author: diaowangtao
 * @Date: 2020-08-24 10:59:12
 * @Description: 区号下拉框
-->
<template>
  <div>
    <el-select v-model="value" placeholder @change="change">
      <el-option
        v-for="(item,index) in areaCodes"
        :key="index"
        :label="item.tel"
        :value="item.areaCode"
        style="width:280px"
      >
        <span style="float: left">{{ item.name }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.tel }}</span>
      </el-option>
    </el-select>
  </div>
</template>


<script>
import areaCodes from '@/utils/area.json'
export default {
  name: 'mySelect',
  props: {},
  data() {
    return {
      areaCodes: [],
      value: 10001,
    }
  },
  created() {
    this.areaCodes = areaCodes
  },
  methods: {
    change(i) {
      let obj = {}
      obj = this.areaCodes.find((item) => {
        return item.areaCode === i
      })
      this.$emit('changeSelect',obj)
    },
  },
  mounted() {},
}
</script>


<style lang="scss" scoped>
</style>
