<!--
 * @Author: diaowangtao
 * @Date: 2020-08-24 09:26:34
 * @Description: 联系我们
-->
<template>
  <div class="contact">
    <my-banner :bg_banner="bg_banner" v-if="bg_banner"></my-banner>
    <div class="w1200">
      <div class="left contact_l">
        <my-caption :title="title" class="title"></my-caption>
        <baidu-map class="map" :center="center" :zoom="zoom" @ready="mapHandler" />
        <div class="message">
          <p class="message_1">
            <span class="message_span left" v-html="$options.filters.sLang('客服电话')"></span>
            <!-- {{content[3].content|sLang}} -->
            <span v-html="$options.filters.sLang('：+852 3702 7888')"></span>
          </p>
          <p class="message_2">
            <span class="message_span left" v-html="$options.filters.sLang('传真')"></span>
            <!-- {{content[4].content|sLang}} -->
            <span v-html="$options.filters.sLang('：+852 3702 7800')"></span>
          </p>
          <p class="message_3">
            <span class="message_span left" v-html="$options.filters.sLang('地址')"></span>
            <!-- {{content[2].content|sLang}} -->
            <span v-html="$options.filters.sLang('：香港中环康乐广场8号交易广场一期11楼1105-1107室')"></span>
          </p>
        </div>
      </div>
      <div class="right contact_r">
        <div class="left con_l">
          <p class="must" v-html="$options.filters.sLang('您的名字')"></p>
          <input
            type="text"
            :placeholder="$t('normal.please')"
            v-model="form.customerName"
            maxlength="20"
            @focus="hasBorder(1)"
            @blur="noBorder"
            :class="[border==1?'border':'']"
          />
          <p class="must" v-html="$options.filters.sLang('您的邮箱')"></p>
          <input
            type="text"
            :placeholder="$t('normal.please')"
            v-model="form.emailInternet"
            maxlength="50"
            @focus="hasBorder(2)"
            @blur="noBorder"
            :class="[border==2?'border':'']"
          />
          <p class="must" v-html="$options.filters.sLang('联系主题')"></p>
          <input
            type="text"
            :placeholder="$t('normal.please')"
            v-model="form.title"
            maxlength="50"
            @focus="hasBorder(3)"
            @blur="noBorder"
            :class="[border==3?'border':'']"
          />
        </div>
        <div class="right con_r">
          <p class="must" v-html="$options.filters.sLang('电话号码')"></p>
          <div class="phone">
            <my-select class="select left" @changeSelect="changeSelect"></my-select>
            <span class="left line">-</span>
            <input
              type="text"
              :placeholder="$t('normal.please')"
              v-model="form.mobileInternet"
              maxlength="20"
              class="right"
              @focus="hasBorder(4)"
              @blur="noBorder"
              :class="[border==4?'border':'']"
              oninput="value=value.replace(/[^\d]/g,'')"
            />
          </div>
          <p v-html="$options.filters.sLang('您的公司名称')"></p>
          <input
            type="text"
            :placeholder="$t('normal.please')"
            v-model="form.compName"
            maxlength="50"
            @focus="hasBorder(5)"
            @blur="noBorder"
            :class="[border==5?'border':'']"
          />
        </div>
        <div style="clear:both"></div>
        <p class="must" v-html="$options.filters.sLang('更多描述')"></p>
        <textarea
          name
          id
          :placeholder="$t('normal.please')"
          v-model="form.content"
          maxlength="500"
          @focus="hasBorder(6)"
          @blur="noBorder"
          :class="[border==6?'border':'']"
        ></textarea>
        <span v-text="$options.filters.sLang(message_text)" class="message"></span>
        <my-button
          :text="button"
          @goToPage="onSubmit"
          class="button"
          :class="[noclick==1?'noclick':'']"
        ></my-button>
      </div>
    </div>
  </div>
</template>

<script>
import myBanner from '@/components/myBanner/index.vue'
import myCaption from '@/components/myCaption/index.vue'
import mySelect from '@/components/mySelect/index.vue'
import myButton from '@/components/myButton/index.vue'
import { contactUs, contactUsDetail, contactBanner } from '@/api/contactUs'
function initForm() {
  return {
    customerName: '',
    areaCode: 10001,
    areaCodeValue: 86,
    mobileInternet: '',
    emailInternet: '',
    compName: '',
    title: '',
    content: '',
  }
}
export default {
  name: 'contact',
  components: {
    myCaption: myCaption,
    myBanner: myBanner,
    mySelect: mySelect,
    myButton: myButton,
  },
  data() {
    return {
      bg_banner: '',
      title: { name: '恒天国际资本管理有限公司', dec: '' },
      center: {
        lng: '',
        lat: '',
      },
      zoom: 18,
      border: 0,
      button: '提交',
      message_text: '  ',
      form: initForm(),
      content: [{},{},{},{},{}],
      noclick: 0,
    }
  },
  created() {
    // contactUsDetail().then((res) => {
    //   console.log(res.article.content)
    //   this.content = res.article.content.split('<p>')
    //   this.content = this.content.map((item) => ({
    //     content: item
    //       .replace('</p>', '')
    //       .replace('地址', '')
    //       .replace('电话', '')
    //       .replace('传真', ''),
    //   }))
    //   console.log(this.content)
    // })
    contactBanner({ pageNo: 1, pageSize: 1 }).then((res) => {
      //console.log(res)
      this.bg_banner = res.list[0].article.coverList[1].oldUrl
    })
  },
  methods: {
    mapHandler({ BMap, map }) {
      // 鼠标缩放
      map.enableScrollWheelZoom(true)
      this.center.lng = 114.169374
      this.center.lat = 22.287062
      this.zoom = 18
      let point = new BMap.Point(this.center.lng, this.center.lat)
      let marker = new BMap.Marker(point) // 创建标注
      map.addOverlay(marker)

      //将map和point存起来
      this.$store.commit('addMap', map)
      this.$store.commit('addPoint', point)

      //添加文字说明
      this.$mapjs();
    },

    hasBorder(i) {
      this.border = i
    },
    noBorder() {
      this.border = 0
    },
    onSubmit(values) {
      let reg = /\s/g
      let mail = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
      if (!this.form.customerName || reg.test(this.form.customerName)) {
        this.message_text = '请填写姓名'
        setTimeout(this.clearText, 1500)
      } else if (
        !this.form.mobileInternet ||
        reg.test(this.form.mobileInternet)
      ) {
        this.message_text = '请填写电话'
        setTimeout(this.clearText, 1500)
      } else if (
        !this.form.emailInternet ||
        reg.test(this.form.emailInternet) ||
        !mail.test(this.form.emailInternet)
      ) {
        this.message_text = '请填写正确的邮箱'
        setTimeout(this.clearText, 1500)
      } else if (!this.form.title || reg.test(this.form.title)) {
        this.message_text = '请填写联系主题'
        setTimeout(this.clearText, 1500)
      } else if (!this.form.content || reg.test(this.form.content)) {
        this.message_text = '请填写更多描述'
        setTimeout(this.clearText, 1500)
      } else {
        this.noclick = 1
        this.message_text = '提交中'
        this.form.customerName = filterXSS(this.form.customerName)
        this.form.mobileInternet = filterXSS(this.form.mobileInternet)
        this.form.emailInternet = filterXSS(this.form.emailInternet)
        this.form.compName = filterXSS(this.form.compName)
        this.form.title = filterXSS(this.form.title)
        this.form.content = filterXSS(this.form.content)
        contactUs(this.form)
          .then((res) => {
            if (res == '操作成功！') {
              this.message_text = '提交成功'
            } else {
              this.message_text = res
            }
            this.form = initForm()
            this.noclick = 0
            setTimeout(this.clearText, 1500)
          })
          .catch((res) => {
            this.$store.commit('changeError', 1)
            setTimeout(this.clearText, 1500)
            this.noclick = 1
          })
      }
    },
    clearText() {
      this.message_text = ''
    },
    changeSelect(i) {
      this.form.areaCode = i.areaCode
      this.form.areaCodeValue = i.tel
    },
  },
}
</script>

<style lang='scss'>
.contact {
  .w1200 {
    overflow: hidden;
    margin-bottom: 100px;
    .contact_l {
      width: 600px;
      .title {
        margin: 60px 0 40px 0;
      }
      .map {
        width: 420px !important;
        height: 420px !important;
        margin-bottom: 40px;
        .anchorBL {
          display: none;
        }
      }
      .message {
        color: #303a51;
        text-align: justify;
        p {
          padding-left: 40px;
          width: 550px;
          clear: both;
          height: 24px;
          line-height: 24px;
        }

        .message_span {
          display: inline-block;
          width: 70px;
          text-align: justify;
          text-align-last: justify;
        }
        .message_span:after {
          content: ' ';
          display: inline-block;
          width: 100%;
        }
        .message_1 {
          background: url('../../assets/img/kefu.png') no-repeat left center;
          background-size: 22px 22px;
        }
        .message_2 {
          background: url('../../assets/img/chuanzhen.png') no-repeat left
            center;
          background-size: 22px 22px;
        }
        .message_3 {
          background: url('../../assets/img/dizhi.png') no-repeat left 2px
            center;
          background-size: 18px 22px;
        }
      }
    }
    .contact_r {
      width: 600px;
      height: 582px;
      background: #f2f3f5;
      border-radius: 4px;
      margin-top: 117px;
      overflow: hidden;
      padding: 0 20px 20px 20px;
      input {
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        margin-top: 15px;
        border: 1px solid #fff;
      }
      .border,
      input:hover,
      textarea:hover {
        border: 1px solid #1e7be2;
      }
      p {
        color: #232d44;
        position: relative;
        margin-top: 28px;
        height: 22px;
        line-height: 22px;
      }
      .must::after {
        content: '*';
        color: red;
        position: absolute;
        top: 2px;
        left: 70px;
        display: block;
      }

      .con_l {
        width: 250px;
      }
      .con_r {
        width: 280px;
        .phone {
          width: 280px;
          height: 40px;
          padding-top: 0px;
          margin-top: 15px;
          .select {
            width: 25%;
            background: #fff;
            input {
              width: 100%;
              text-align: center;
              padding-right: 20px;
              border-radius: 0;
              background: rgba(0, 0, 0, 0);
              position: relative;
              z-index: 2;
            }
            .el-select .el-input.is-focus .el-input__inner {
              border-color: #1e7be2;
            }
            .el-input__suffix {
              right: 1px;
            }
          }
          .line {
            height: 40px;
            line-height: 40px;
            text-align: center;
            width: 10%;
          }
          input {
            width: 65%;
            margin-top: 0;
          }
        }
      }
      textarea {
        width: 100%;
        display: block;
        height: 120px;
        border: 1px #fff solid;
        resize: none;
        padding: 10px;
        outline: none;
        margin-top: 15px;
        font-family: PingFangSC-Regular;
      }
      .message {
        display: block;
        text-align: center;
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        color: red;
      }
    }
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      font-size: 14px;
      color: #8d909b;
      font-family: PingFangSC-Regular;
    }
    .button {
      background: #dab678;
      margin: 0 auto;
      padding-right: 0;
    }
    .button:hover {
      background: #daaa57;
    }
  }
  .noclick {
    pointer-events: none;
  }
}
</style>