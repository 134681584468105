<!--
 * @Author: diaowangtao
 * @Date: 2020-08-19 17:22:10
 * @Description: 小标题组件
-->
<template>
  <div class="caption" :title="title">
    <div class="left"></div>
    <p class="left" Title="">{{title.name|sLang}}</p>
    <span class="left" Title="">{{title.dec|sLang}}</span>
  </div>
</template>

<script>
export default {
  name: 'myCaption',
  props: {
    title: '',
  },
}
</script>

<style lang='scss'>
.caption {
  overflow: hidden;
  div {
    width: 14px;
    height: 18px;
    background: #dab678;
  }
  p {
    font-size: 18px;
    color: #303a51;
    line-height: 18px;
    margin-left: 10px;
  }
  span {
    font-size: 14px;
    color: #8d909b;
    margin-left: 10px;
    line-height: 26px;
  }
}
</style>